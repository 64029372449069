.zh-kefu-index {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    & * {
        box-sizing: border-box;
    }
    .kf-column-left {
        width: 120px;
        height: 100%;
        background-color: #0084FF;
        .kfl-logo-tab {
            width: 100%;
            height: 170px;
            display: flex;
            justify-content: center;
            align-items: center;
            .kl-logo-box {
                width: 80px;
                height: 80px;
                border-radius: 40px;
                overflow: hidden;
                img {
                    width: 80px;
                    height: 80px;
                }
            }
        }
        .kfl-kefu-tab {
            width: 100%;
            height: 60px;
            background-color: #007CF2;
            .kl-kefu-tab-btn {
                width: 100%;
                height: 100%;
                text-align: center;
                line-height: 58px;
                color: #FFF;
                i.chats-icon {
                    font-size: 20px;
                    margin-right: 8px;
                }
                span.tab-btn {
                    font-size: 16px;
                    font-weight: 700;
                    font-family: "Microsoft Yahei", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                }
            }
        }
        .kfl-bg-qu {
            width: 100%;
            height: calc(100% - 230px);
            background: url(../../../assets/kefu/bg.png) no-repeat bottom left;
        }
    }
    .kf-column-middle {
        width: 410px;
        height: 100%;
        background-color: #FFF;
        border-right: 1px solid #E5E5E5;
        .kfm-user-name {
            width: 100%;
            height: 79px;
            background-color: #1A92FF;
            padding-left: 32px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span.kfm-name-qu {
                color: #FFF;
                font-size: 18px;
            }
            .kfm-user-logo {
                width: 48px;
                height: 48px;
                border-radius: 24px;
                overflow: hidden;
                margin-right: 10px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .kfm-icons-tabs {
            width: 100%;
            .kfm-icons-level-1 {
                height: 55px;
                border-bottom: 1px solid #E5E5E5;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 29px;
                .kfm-tab-box {
                    width: 29px;
                    height: 100%;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    &.active::before {
                        display: block;
                        content: '';
                        width: 29px;
                        height: 2px;
                        background-color: #3484F7;
                        position: absolute;
                        bottom: 0px;
                        left: 0px;
                    }
                    &.active i.iconfont {
                        color: #0084FF;
                    }
                    &.tab-icon-clock {
                        margin-left: 33px;
                    }
                }
            }
            .kfm-icons-level-2 {
                width: 100%;
                height: 40px;
                border-bottom: 1px solid #EEEEEE;
                padding-left: 29px;
                .kfm-sort-content {
                    height: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                    & i.iconfont {
                        font-size: 15px;
                        margin-right: 11px;
                    }
                    & span.kfm-sort-text {
                        font-size: 17px;
                    }
                }
            }
        }
        .kfm-customers {
            width: 100%;
            height: calc(100% - 174px);
            .kfm-customers-header {
                height: 48px;
                width: 100%;
                padding-left: 36px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .kfm-c-header-text {
                    height: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    i.iconfont {
                        display: inline-block;
                        height: 14px;
                        width: 8px;
                        transform: rotate(90deg);
                        position: relative;
                        top: -3px;
                    }
                    span.tab-title {
                        margin-left: 10px;
                    }
                    span.robot-progress {
                        b {
                            font-size: 16px;
                        }
                    }
                }
            }
            .kfm-customers-list {
                width: 100%;
                height: calc(100% - 48px);
                ul,
                li {
                    list-style-type: none;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }
                li.active {
                    background-color: #F7F9FB;
                }
                .kfm-cust-lists {
                    /deep/ .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                    height: 100%;
                    .customer-robot {
                        transition: all 1s linear;
                        width: 100%;
                        height: 80px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 0px 21px 0px 31px;
                        .kfm-robot-avatar {
                            width: 48px;
                            height: 48px;
                            border-radius: 24px;
                            overflow: hidden;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .kfm-robot-name-list {
                            height: 48px;
                            flex: 1;
                            .kfm-robot-name-history {
                                color: #9A9A9A;
                                font-size: 16px;
                                font-weight: 700;
                                padding-left: 9px;
                                margin-bottom: 5px;
                            }
                            .kfm-robot-name {
                                font-size: 16px;
                                padding: 7px 0 3px 11px;
                                color: #333;
                                font-weight: 700;
                            }
                            .kfm-robot-response {
                                font-size: 14px;
                                padding-left: 9px;
                                &.kfm-robot-response-history {
                                    color: #9A9A9A;
                                }
                            }
                        }
                        .krm-custom-ring {
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            & i.active {
                                color: #FD4446;
                                animation: ringing 300ms linear infinite alternate;
                            }
                        }
                    }
                }
            }
        }
    }
    .kr-column-right {
        flex: 1;
        height: 100%;
        .kr-column-header {
            width: 100%;
            height: 79px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            border-bottom: 1px solid #D1D2DB;
            .kr-time-count {
                flex: 1;
                line-height: 77px;
                text-align: center;
                span.kr-time-item {
                    font-size: 18px;
                    color: #FE4447;
                    font-weight: 700;
                }
            }
            .kr-action-btn {
                width: 420px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 30px;
                .kr-action-btn-box {
                    cursor: pointer;
                    font-size: 16px;
                    i.iconfont {
                        font-size: 18px;
                    }
                }
            }
        }
        .kr-column-content {
            width: 100%;
            height: calc(100% - 80px);
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .kr-robot-main {
                flex: 1;
                height: 100%;
                display: flex;
                flex-direction: column;
                .kr-robot-name-center {
                    width: 100%;
                    height: 56px;
                    text-align: center;
                    line-height: 54px;
                    position: relative;
                    border-bottom: 1px solid #E5E5E5;
                    border-right: 1px solid #D2D2DC;
                    .kr-student-response-time {
                        position: absolute;
                        width: 190px;
                        height: 56px;
                        top: 0;
                        right: 0;
                    }
                    .robot-item-name {
                        font-weight: bold;
                        font-size: 16px;
                    }
                }
                .kr-student-robot-content {
                    flex: 1;
                    width: 100%;
                    background-color: #F2F2F2;
                    height: calc(100% - 356px);
                    //padding-bottom: 30px;
                    /deep/ .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                    p {
                        margin: 0;
                        padding: 0;
                    }
                    ul,
                    li {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                    }
                    ul {
                        padding-bottom: 20px;
                    }
                    li.kr-robot-say {
                        padding: 20px 20px 0px 20px;
                        // width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        .kr-robot-say-item {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            .kr-robot-avatar {
                                width: 40px;
                                height: 40px;
                                border-radius: 20px;
                                overflow: hidden;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .kr-robot-sayC {
                                margin-left: 17px;
                                .kr-robot-small-title {
                                    font-size: 12px;
                                    color: #666666;
                                    span {
                                        padding-left: 5px;
                                        color: #999999;
                                    }
                                }
                                .kr-robot-saying {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    margin-top: 10px;
                                    position: relative;
                                    &:before {
                                        content: '';
                                        width: 0;
                                        height: 0;
                                        border: 4px solid #FFF;
                                        position: absolute;
                                        top: 0px;
                                        left: -8px;
                                        border-left-color: transparent;
                                        border-bottom-color: transparent;
                                    }
                                    p {
                                        background-color: #FFF;
                                        border-top-right-radius: 5px;
                                        border-bottom-right-radius: 5px;
                                        border-bottom-left-radius: 5px;
                                        padding: 5px;
                                        color: #343434;
                                    }
                                }
                            }
                        }
                    }
                    .kr-student-say {
                        padding: 20px 20px 0px 20px;
                        // width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-start;
                        .kr-stu-say-item {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            .kr-stu-sayC {
                                margin-right: 17px;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                .kr-stu-small-title {
                                    font-size: 12px;
                                    color: #343434;
                                    span {
                                        padding-left: 5px;
                                        color: #333333;
                                    }
                                }
                                .kr-stu-saying {
                                    margin-top: 10px;
                                    position: relative;
                                    max-width: 85%;
                                    &:before {
                                        content: '';
                                        width: 0;
                                        height: 0;
                                        border: 4px solid #D2EAFF;
                                        border-right-color: transparent;
                                        border-bottom-color: transparent;
                                        position: absolute;
                                        top: 0;
                                        right: -8px;
                                    }
                                    .kr-stu-talk-content {
                                        padding: 5px;
                                        border-top-left-radius: 5px;
                                        border-bottom-left-radius: 5px;
                                        border-bottom-right-radius: 5px;
                                        background-color: #D2EAFF;
                                        color: #343434;
                                        /deep/ p {
                                            margin: 0px;
                                            padding: 0px;
                                            img {
                                                max-width: 300px;
                                                max-height: 200px;
                                            }
                                        }
                                    }

                                }
                            }
                            .kr-stu-avatar {
                                width: 40px;
                                height: 40px;
                                border-radius: 20px;
                                overflow: hidden;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
                .kr-editor-box {
                    width: 100%;
                    height: 300px;
                    position: relative;
                    overflow: auto;
                    #send-content {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        /deep/ .w-e-text {
                            p {
                                img {
                                    max-width: 200px;
                                    max-height: 100px;
                                }
                            }
                        }
                    }
                    .kr-stu-send-content {
                        position: absolute;
                        bottom: 10px;
                        right: 32px;
                        z-index: 999;
                        i.iconfont {
                            margin-right: 5px;
                        }
                        span {
                            font-weight: 700;
                        }
                    }
                }
            }
            .kr-robot-description {
                width: 420px;
                height: 100%;
                .kr-kefu-btns {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 30px;
                    margin-bottom: 20px;
                }
                .kr-descript-htitle {
                    width: 100%;
                    height: 57px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 30px;
                    i.iconfont {
                        color: #3484F7;
                        font-size: 16px;
                        margin-right: 9px;
                    }
                    h1 {
                        padding: 0px;
                        margin: 0px;
                        font-size: 24px;
                    }
                }
                .kr-descript-content {
                    margin: 25px 56px 20px 30px;
                    width: 325px;
                    height: 203px;
                    border-bottom: 1px solid #EEEEEE;
                    p {
                        width: 100%;
                        height: 100%;
                        color: #666666;
                        font-size: 18px;
                    }
                    /deep/ .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                }
                .kr-robot-shop-goods {
                    width: 100%;
                    height: calc(100% - 365px);
                    ul,
                    li {
                        margin: 0px;
                        padding: 0px;
                        list-style: none;
                    }
                    .kr-goods-title {
                        width: 100%;
                        padding-left: 30px;
                        span {
                            font-size: 18px;
                            color: #343434;
                        }
                    }
                    ul.kr-robot-goods-list {
                        margin-top: 19px;
                        height: calc(100% - 43px);
                        /deep/ .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }
                        li {
                            width: 100%;
                            height: 101px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            padding: 0px 56px 30px 30px;
                            .goods-item {
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;
                                cursor: pointer;
                                .kr-robot-goods-img {
                                    width: 73px;
                                    height: 73px;
                                    overflow: hidden;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                                .kr-robot-goods-content {
                                    flex: 1;
                                    width: 261px;
                                    .kr-robot-goods-name {
                                        margin-left: 9px;
                                        width: calc(100% - 9px);
                                        height: 18px;
                                        font-size: 16px;
                                        color: #343434;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                    .kr-robot-goods-price {
                                        margin: 19px 0 0 12px;
                                        font-size: 14px;
                                        color: #FF0000;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @keyframes ringing {
        0% {
            transform: rotate(-60deg);
        }
        25% {
            transform: rotate(-30deg);
        }
        50% {
            transform: rotate(0deg);
        }
        75% {
            transform: rotate(30deg);
        }
        100% {
            transform: rotate(60deg);
        }
    }
}